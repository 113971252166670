import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './RFQsTable.scss';
import { useTranslation } from 'react-i18next';
import StatusCell from './cell-renderers/StatusCell/StatusCell';
import CostCell from 'common/components/Table/cell-renderers/CostCell/CostCell';
import Table, { TableColumnT, TableRowMetaT } from 'common/components/Table/Table';
import LocationCell from './cell-renderers/LocationCell/LocationCell';
import CreatedCell from './cell-renderers/CreatedCell/CreatedCell';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import { RFQT } from 'common/store/rfqs/models';
import { DirectionEnum } from 'common/constants';
import TrailerTypeCell from './cell-renderers/TrailerTypeCell/TrailerTypeCell';
import ExternalNumberCell from 'common/layouts/RFQListPageLayout/RFQsTable/cell-renderers/ExternalNumberCell/ExternalNumberCell';

const cx = classNames.bind(styles);

type PropsT = {
    rfqs: Array<RFQT>;
    className?: string;
    goToRFQDetails: (event: React.MouseEvent, rfqId: RFQIdT) => void;
    goToUserDetails: (userId: UserIdT) => void;
    isLoading: boolean;
};

const RFQsTable: React.FC<PropsT> = React.memo((props) => {
    const { rfqs, className, goToUserDetails, goToRFQDetails, isLoading } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<RFQT, void>> = [
        {
            renderHeader: () => <span>{t('common:rfqs-page.table.columns.origin')}</span>,
            headerClassName: cx('table__header', 'table__header--origin'),
            render: (rfq: RFQT) => <LocationCell rfq={rfq} type={DirectionEnum.origin} />,
            className: cx('table__column', 'table__column--origin'),
            testSelector: 'origin',
        },
        {
            renderHeader: () => <span>{t('common:rfqs-page.table.columns.destination')}</span>,
            headerClassName: cx('table__header'),
            render: (rfq: RFQT) => <LocationCell rfq={rfq} type={DirectionEnum.destination} />,
            className: cx('table__column', 'table__column--destination'),
            testSelector: 'destination',
        },
        {
            renderHeader: () => <span>{t('common:rfqs-page.table.columns.created')}</span>,
            headerClassName: cx('table__header'),
            render: (rfq: RFQT) => <CreatedCell onOpenUser={goToUserDetails} rfq={rfq} />,
            className: cx('table__column', 'table__column--create'),
            testSelector: 'created',
        },
        {
            renderHeader: () => <span>{t('common:rfqs-page.table.columns.external-number')}</span>,
            headerClassName: cx('table__header', 'table__header--external-number'),
            render: (rfq: RFQT) => <ExternalNumberCell number={rfq?.externalNumber} />,
            className: cx('table__column', 'table__column--external-number'),
            testSelector: 'external-number',
        },

        {
            renderHeader: () => <span>{t('common:rfqs-page.table.columns.price-offer')}</span>,
            headerClassName: cx('table__header'),
            render: (rfq: RFQT) => <CostCell cost={rfq.price} />,
            className: cx('table__column'),
            testSelector: 'price-offer',
        },
        {
            renderHeader: () => <span>{t('common:rfqs-page.table.columns.trailer')}</span>,
            headerClassName: cx('table__header'),
            render: (rfq: RFQT) => <TrailerTypeCell rfq={rfq} />,
            className: cx('table__column'),
            testSelector: 'trailer',
        },
        {
            renderHeader: () => <span>{t('common:rfqs-page.table.columns.status')}</span>,
            headerClassName: cx('table__header'),
            render: (rfq: RFQT) => <StatusCell rfq={rfq} />,
            className: cx('table__column', 'table__column--status'),
            testSelector: 'status',
        },
    ];

    const handleSelectRow = (event: React.MouseEvent, rfq: RFQT) => {
        if (!rfq.id) {
            return;
        }

        goToRFQDetails(event, rfq.id);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getRowMods = (meta: TableRowMetaT, rfq: RFQT) => {
        return {};
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<RFQT> tableName="rfqs" isLoading={isLoading} rows={rfqs}>
                {(rows, isUsedPrevRows) => (
                    <Table<RFQT, void>
                        testSelector="rfqs"
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        onSelectRow={handleSelectRow}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default RFQsTable;
