import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ExternalNumberCell.scss';

type PropsT = {
    number: string | null | undefined;
};

const cx = classNames.bind(styles);

const ExternalNumberCell: React.FC<PropsT> = React.memo((props) => {
    const { number } = props;

    return <div className={cx('number')}>{number}</div>;
});

export default ExternalNumberCell;
